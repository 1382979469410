module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-ddfb9b73a8/0/cache/gatsby-remark-images-npm-6.6.0-45ea520599-367520e4ca.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;","quality":80,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-3abbf91670/0/cache/gatsby-plugin-manifest-npm-4.6.0-56378513ae-91cd06db9c.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Snowflake","short_name":"Snowflake","start_url":"/","background_color":"#fff","theme_color":"#d4e5ff","display":"minimal-ui","icons":[{"src":"/img/pwa-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/img/pwa-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-c86f69d5a7/0/cache/gatsby-plugin-catch-links-npm-4.6.0-b2b9cc45a8-16bac211b6.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-72947110e9/0/cache/gatsby-plugin-offline-npm-5.6.0-2f7c8fedd7-7b24d6059c.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-twitter-virtual-6d7352f4f7/0/cache/gatsby-plugin-twitter-npm-4.6.0-1e15830373-04f1688357.zip/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
